import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Break = () => (
  <div className="section section-dark">
    <Container>
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2 className="title">Thanks for visiting</h2>
          <p className="break">
            I'm always adding and working on new projects. I'll be sure to add
            them here as soon as they're finished. Check back to see my latest
            work!
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Break;
