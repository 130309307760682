import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import ProjectCarousel from '../../components/ProjectCarousel';

const About = ({ horizontalImgs }) => {
  const [smallImg, setSmallImg] = React.useState(window.innerWidth > 760);
  const debounce = (fn, ms) => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout((...args) => {
        timer = null;
        fn.apply(this, args);
      }, ms);
    };
  };

  React.useEffect(() => {
    const handleResize = debounce(() => {
      window.innerWidth <= 760 ? setSmallImg(false) : setSmallImg(true);
    }, 1000);

    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, [smallImg]);

  return (
    <div className="section">
      <Container className="tim-container">
        <div className="title">
          <h2 name="about_me" id="about_me">
            About Me
          </h2>
        </div>
        <div id="typography">
          <Row>
            <Col className="mr-auto ml-auto" md="2" sm="3">
              <div className={smallImg ? '' : 'img-details'}>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require('assets/img/mar.jpg')}
                />
              </div>
            </Col>
            <Col>
              <blockquote className="blockquote">
                <p className="description mb-0">
                  My name is Mar Blay and I am an aspiring costume designer and
                  maker, based in Dublin and originally from Spain. I am
                  currently finishing a degree in Costume Design in IADT and
                  previously studied a Fashion Design course. I'm interested in
                  historical fashion research, recreation, and construction. I'm
                  an avid reader of fantasy and I enjoy drawing and designing
                  the costumes of the characters from the books I read. A
                  cinephile at heart, I love exploring costume choices in film
                  and TV. In my free time, I enjoy playing video games, going
                  hiking, and baking.
                </p>
              </blockquote>
              <br />
              <Row>
                <Col lg="12">
                  <ProjectCarousel items={horizontalImgs} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default About;
