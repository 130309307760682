import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

import ProjectCarousel from './ProjectCarousel';

const Project = ({ project }) => {
  const { imgs, description, sketches } = project;
  const [showSketches, setShowSketches] = React.useState(false);
  const [images, setImages] = React.useState(imgs);
  const [mobileView, setMobileView] = React.useState(window.innerWidth <= 760);
  const toggleSketches = (shouldDisplaySketches) => {
    setShowSketches(shouldDisplaySketches);
    shouldDisplaySketches ? setImages(sketches) : setImages(imgs);
  };

  const debounce = (fn, ms) => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout((...args) => {
        timer = null;
        fn.apply(this, args);
      }, ms);
    };
  };

  React.useEffect(() => {
    const handleResize = debounce(() => {
      window.innerWidth <= 760 ? setMobileView(true) : setMobileView(false);
    }, 1000);

    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mobileView]);

  return (
    <Container>
      <>
        <Row>
          <Col md="10">
            {mobileView && <h3 className="text-center">{project.title}</h3>}
            <div
              className={
                window.innerWidth <= 760
                  ? 'text-center'
                  : 'typography-line text-center'
              }
            >
              <p className="description mb-0">{description}</p>
              {sketches && (
                <Button
                  onClick={() => toggleSketches(!showSketches)}
                  className="btn-round "
                  color="neutral"
                  type="button"
                >
                  Check out{' '}
                  {showSketches
                    ? 'the final images captured'
                    : 'my early sketches'}{' '}
                  for this project
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" lg="6">
            <ProjectCarousel items={images} />
          </Col>
        </Row>
      </>
    </Container>
  );
};

export default Project;
