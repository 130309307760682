import Project from 'components/Project';
import React from 'react';
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from 'reactstrap';

const TabItem = ({ project, ix, activeTab, toggle }) => {
  const [showTitle, setShowTitle] = React.useState(window.innerWidth > 760);

  const debounce = (fn, ms) => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout((...args) => {
        timer = null;
        fn.apply(this, args);
      }, ms);
    };
  };

  React.useEffect(() => {
    const handleResize = debounce(() => {
      window.innerWidth <= 760 ? setShowTitle(false) : setShowTitle(true);
    }, 1000);

    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showTitle]);

  return (
    <NavItem>
      <NavLink
        className={activeTab === ix.toString() ? 'description active' : ''}
        onClick={() => {
          toggle(ix.toString());
        }}
      >
        {showTitle ? project.title : ix + 1}
      </NavLink>
    </NavItem>
  );
};

const Projects = ({ projects }) => {
  const [activeTab, setActiveTab] = React.useState('0');
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <Container className="tim-container">
      <>
        <div id="projects" className="title">
          <h2>Projects</h2>
          <br />
        </div>

        <Row>
          <Col lg="16">
            <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <Nav id="tabs" role="tablist" tabs>
                  {projects &&
                    projects.map((project, ix) => (
                      <TabItem
                        project={project}
                        ix={ix}
                        activeTab={activeTab}
                        toggle={toggle}
                        key={ix}
                      />
                    ))}
                </Nav>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabContent activeTab={activeTab}>
              {projects &&
                projects.map((project, ix) => (
                  <TabPane tabId={ix.toString()} key={ix}>
                    <Project project={project} />
                  </TabPane>
                ))}
            </TabContent>
          </Col>
        </Row>
      </>
    </Container>
  );
};

export default Projects;
