import React from 'react';

import { Row, Container } from 'reactstrap';

function Footer() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <div className="credits ml-auto">
            <a
              data-placement="bottom"
              href="https://www.instagram.com/marblay_design"
              target="_blank"
              rel="noopener noreferrer"
              title="Follow me on Instagram"
            >
              <i className="fa fa-instagram" />
            </a>
            <span className="copyright">
              © {new Date().getFullYear()}, Mar Blay Gomez
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
