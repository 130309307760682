import React from 'react';

import { Container } from 'reactstrap';

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          // backgroundImage: `url(https://downloads.intercomcdn.com/i/o/297067559/6fd6244b57b53f75745b0200/hero.jpg)`
          backgroundImage:
            'url(https://downloads.intercomcdn.com/i/o/340247293/43f84ac8a6f77ccaa2206613/hero.jpg)'
          // backgroundImage: 'url(' + require('assets/img/hero.jpg') + ')'
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">Mar Blay</h1>
            </div>
            <h2 className="presentation-subtitle text-center">
              Costume design
            </h2>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
