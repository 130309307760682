/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components

// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.js';
import IndexHeader from 'components/Headers/IndexHeader.js';
import Footer from 'components/Footers/Footer.js';

// index sections
import About from './sections/About.js';
import Projects from './sections/Projects.js';
import Contact from './sections/Contact.js';
import Break from './sections/Break.js';

function Index() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('index');
    return function cleanup() {
      document.body.classList.remove('index');
    };
  });

  const buildImageSrcs = (size, name, alt, sketch = false) =>
    [...Array(size).keys()].map((i) => ({
      src: `${name}/${i + 1}.jpg`,
      altText: `${alt ? `${alt},` : ''} ${sketch ? 'sketch' : 'image'} ${i + 1}`
    }));

  const projects = [
    {
      title: 'Mistborn by Brandon Sanderson',
      description:
        "This was the major and final project of my degree. Using a script based on the first book of the Mistborn series, 'The Final Empire', adapted for television. The world in Mistborn is harshly controlled by a God that calls himself the Lord Ruler. The costume that I designed and made was the first outfit used by Vin, disguised as Lady Valette to infiltrate the nobility, in an attempt to fight against the tyranny of the Empire. I designed the main cast with different concepts to fit the different social classes that they come from. The majority of the costume was hand sewn.",
      imgs: buildImageSrcs(11, 'mistborn', 'Mistborn by Brandon Sanderson'),
      sketches: buildImageSrcs(
        16,
        'mistborn-sketches',
        'Mistborn by Brandon Sanderson',
        true
      )
    },
    {
      title: 'The Importance Of Being Earnest',
      description:
        'For this project I designed the entire cast for the film and made the character of Cecily. I researched the historical fashion of 1895 to give an accurate silhouette. I made the undergarments needed for the correct shape including a corset. The dress is a two separated pieces garment.',
      imgs: buildImageSrcs(10, 'earnest', 'Importance of Being Earnest'),
      sketches: buildImageSrcs(
        13,
        'earnest-sketches',
        'Importance of Being Earnest',
        true
      )
    },
    {
      title: 'Opera La Calisto',
      description:
        'I was the co-lead designer for "La Calisto" directed by Sinead O\'Neil. Performed in the RIAM in  Dublin 2020. I designed the characters of Juno, Calisto and Diana. Working closely with the Director. I made the costume of Juno and sourced for Calisto and Diana. Juno\'s costume consists on a bodice with sequence fabric, a standing collar with golden peacock feathers, a golden belt and a semicircle skirt.',
      imgs: buildImageSrcs(10, 'opera', 'La Calisto'),
      sketches: buildImageSrcs(12, 'opera-sketches', 'La Calisto', true)
    },

    {
      title: 'Victorian Undergarments & Toile',
      description:
        'Undergarments and toile I made in second year of my degree. A corset, bustle and petticoat to go under the Victorian dress, made with calico and trims. I used the pattern in the book "Patterns of fashion" by Janet Arnold.',
      imgs: buildImageSrcs(
        7,
        'victorian-undergarments',
        'Ladies Victorian Undergarments'
      )
    },
    {
      title: 'Gormenghast by Mervyn Peake',
      description:
        "Gormenghast is set on a Gothic Victorian decaying city state with a castle. It's a place of hierarchy and rituals. The castle is full of ghosts and demons. I designed the characters with a Victorian style tonned down, with the master of rituals wearing a cult-like robe.",
      imgs: buildImageSrcs(18, 'gormenghast', 'Gormenghast')
    },
    {
      title: 'Translations by Brian Friel',
      description:
        "Project based on the script of Translations set in the rural Ireland of the 19th century. Researched about the historical clothes the characters would wear as well as the specific uniform that the British soldiers would've worn at the year it was set.",
      imgs: buildImageSrcs(15, 'translations', 'Translations by Brian Friel')
    },
    {
      title: 'Orlando by Victoria Woolf',
      description:
        'A study of fashion history making drawings that follow the story of the main character, Orlando, on their transition through 400 years of history, from the Elizabethan era to the 90s. The appearance changes following the fashion of the time and gender change.',
      imgs: buildImageSrcs(37, 'orlando', 'Orlando by Victoria Woolf')
    },
    {
      title: 'Room in New York - Viewfinder',
      description:
        'Three short movies based on the same painting by Edward Hopper. They end on the same shot but have a different plot. An abusive relationship, a dinner with a friend and a futuristic dinner. I sourced, altered and dressed the characters on the 3 short films.',
      imgs: buildImageSrcs(14, 'viewfinder', 'Room in New York - Viewfinder')
    },
    {
      title: 'Marieta - Puppet Film',
      description:
        'A short puppet film based on a kids story from my childhood, she gets lost in the forest and a macabre being follows her home. I made and designed the puppet, the small teddy bear and the costume for Marieta.',
      imgs: buildImageSrcs(7, 'marieta', 'Marieta, Puppet Film')
    }
  ];

  return (
    <>
      <IndexNavbar />
      <IndexHeader />
      <div className="main">
        <About horizontalImgs={buildImageSrcs(12, 'horizontals')} />
        <Projects projects={projects} />
        <Break />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default Index;
