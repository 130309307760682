import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

const Contact = () => (
  <div className="section">
    <Container className="text-center">
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2 id="contact" className="title">
            Contact me
          </h2>
          <p className="description">
            If you're interested in working with me, or just have more questions
            about what you've seen here, please reach out!
          </p>

          <Button href="mailto:marblaygomez@gmail.com" color="neutral">
            <h3 className="mb-0">marblaygomez@gmail.com</h3>
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-md-center sharing-area text-center">
        <Col className="text-center" lg="8" md="12">
          <Button
            className="instagram-sharrre btn-round ml-2"
            href="https://www.instagram.com/marblay_design"
            color="instagram-bg"
            id="tooltip3373767"
          >
            <i className="fa fa-instagram" /> Instagram
          </Button>
          <Button
            className="linkedin-sharrre btn-round  ml-2"
            color="google-bg"
            href="mailto:marblaygomez@gmail.com"
            id="tooltip840791273"
          >
            <i className="fa fa-envelope" /> Email
          </Button>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Contact;
